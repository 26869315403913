import { Box } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

export interface Props {
  isOpen: boolean;
}

export const Flasher: FC<Props> = memo(({ isOpen }) => {
  return (
    <Box
      bg="white"
      bottom="0"
      data-testid="Flasher"
      left="0"
      opacity={isOpen ? '.8' : '0'}
      pos="absolute"
      right="0"
      top="0"
      transition={`all cubic-bezier(0, 1.75, 1, 1.5) 300ms`}
      visibility={isOpen ? 'visible' : 'hidden'}
      zIndex="3"
    />
  );
});

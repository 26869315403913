import { Box, Spinner } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

import type { CameraMode, CameraState, FacingMode } from './types';

export interface Props {
  currentFacingMode: FacingMode;
  mode: CameraMode;
  refs: any;
  state: CameraState;
}

export const CameraCanvas: FC<Props> = memo(({ currentFacingMode, mode, refs, state }) => {
  return (
    <>
      <video
        autoPlay
        id="video"
        muted
        playsInline
        ref={refs.player}
        style={{
          display: state === 'initializing' ? 'none' : 'block',
          transform: `rotateY(${currentFacingMode === 'user' ? '180deg' : '0deg'})`,
        }}
      />
      {state === 'ready' || mode !== 'takePhoto' ? null : (
        <Box data-testid="CameraLoading">
          <Spinner size="xl" />
        </Box>
      )}
      <canvas ref={refs.canvas} style={{ display: 'none' }} />
    </>
  );
});

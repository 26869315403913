/* eslint-disable complexity */
import type { FacingMode, SetError, SetNumberOfCameras, StreamOrNull } from './types';

const handleSuccess = (stream: MediaStream, setNumberOfCameras: SetNumberOfCameras) => {
  navigator.mediaDevices
    .enumerateDevices()
    .then((r) => setNumberOfCameras(r.filter((i) => i.kind === 'videoinput').length));

  return stream;
};

const errorsByCamera = {
  chrome: {
    block: { msg: 'Permission dismissed', name: 'NotAllowedError' },
    dismissed: { msg: 'Permission denied', name: 'NotAllowedError' },
    systemBlock: { msg: 'Could not start video source', name: 'NotReadableError' },
  },
  firefox: {
    block: {
      msg: 'The request is not allowed by the user agent or the platform in the current context.',
      name: 'NotAllowedError',
    },
    dismissed: {
      msg: 'The request is not allowed by the user agent or the platform in the current context.',
      name: 'NotAllowedError',
    },
    systemBlock: {
      msg: 'The object can not be found here.',
      name: 'NotFoundError',
    },
  },
  safari: {
    block: {
      msg: 'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
      name: 'NotAllowedError',
    },
    dismissed: undefined,
    systemBlock: undefined,
  },
};

const handleError = (error: Error, setError: SetError) => {
  // eslint-disable-next-line no-console
  console.error('msg: ', error.message);
  // eslint-disable-next-line no-console
  console.error('name: ', error.name);

  if (error.name === errorsByCamera.chrome.block.name || error.name === 'NotReadableError') {
    setError('permissionDenied');
  } else {
    setError('noCameraAccessible');
  }
};

export const initCameraStream = async (
  stream: StreamOrNull,
  currentFacingMode: FacingMode,
  setNumberOfCameras: SetNumberOfCameras,
  setError: SetError
): Promise<StreamOrNull> => {
  // stop any active streams in the window
  if (stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  const constraints = {
    audio: false,
    video: { facingMode: currentFacingMode, height: { ideal: 1920 }, width: { ideal: 1920 } },
  };

  try {
    if (navigator?.mediaDevices?.getUserMedia) {
      const nextStream = await navigator.mediaDevices.getUserMedia(constraints);
      handleSuccess(nextStream, setNumberOfCameras);
      return nextStream;
    }
  } catch (error: any) {
    handleError(error, setError);
  }
  return null;
};

/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { useEffect, useRef, useState } from 'react';

import { useEvent } from '../../../hooks/useEvent';
import { useIsMounted } from '../../../hooks/useIsMounted';

import { initCameraStream } from './initCameraStream';
import type {
  CameraError,
  CameraMode,
  CameraProps,
  CameraState,
  FacingMode,
  StreamOrNull,
} from './types';

export const useCamera = ({ facingMode = 'user', mount = true }: CameraProps) => {
  const [mode, _setMode] = useState<CameraMode>('takePhoto');
  const [photos, setPhotos] = useState<string[]>([]);
  const [photoIdx, setPhotoIdx] = useState(0);
  const player = useRef<HTMLVideoElement | null>(null);
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  const stream = useRef<StreamOrNull>(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [currentFacingMode, setFacingMode] = useState<FacingMode>(facingMode);
  const [error, setError] = useState<null | CameraError>(null);
  const [state, setState] = useState<CameraState>('initializing');
  const isCurrentlyVisible = useRef(true);

  const switchCamera = useEvent(() => {
    if (numberOfCameras < 1) {
      return setError('noCameraAccessible');
    } else if (numberOfCameras < 2) {
      // eslint-disable-next-line no-console
      console.error('Error: Unable to switch camera. Only one device is accessible.'); // console only
      return;
    }
    const newFacingMode = currentFacingMode === 'user' ? 'environment' : 'user';
    setFacingMode(newFacingMode);
    return newFacingMode;
  });

  const isMounted = useIsMounted();

  const takePhoto = useEvent((): string => {
    if (numberOfCameras < 1) {
      setError('noCameraAccessible');
      return 'error';
    }

    if (canvas?.current) {
      const playerWidth = player?.current?.videoWidth || 1280;
      const playerHeight = player?.current?.videoHeight || 720;
      const playerAR = playerWidth / playerHeight;

      const canvasWidth = container?.current?.offsetWidth || 1280;
      const canvasHeight = container?.current?.offsetHeight || 1280;
      const canvasAR = canvasWidth / canvasHeight;

      let sX;
      let sY;
      let sW;
      let sH;

      if (playerAR > canvasAR) {
        sH = playerHeight;
        sW = playerHeight * canvasAR;
        sX = (playerWidth - sW) / 2;
        sY = 0;
      } else {
        sW = playerWidth;
        sH = playerWidth / canvasAR;
        sX = 0;
        sY = (playerHeight - sH) / 2;
      }

      canvas.current.width = sW;
      canvas.current.height = sH;

      const context = canvas.current.getContext('2d');
      // IMPROVE: Remove this code if we are not going to mirror the image
      // const mirrorTheImage = context && currentFacingMode === 'user';
      // if (mirrorTheImage) {
      //   context.translate(sW, 0);
      //   context.scale(-1, 1);
      // }
      if (context && player?.current) {
        context.drawImage(player.current, sX, sY, sW, sH, 0, 0, sW, sH);
      }

      return canvas.current.toDataURL('image/png');
    }

    setError('canvasNotSupported');
    return 'error';
  });

  /** camera in error if next stream is null */
  const mountCamera = useEvent(async () => {
    setError(null);
    const nextStream = await initCameraStream(
      stream.current,
      currentFacingMode,
      setNumberOfCameras,
      setError
    );

    if (isMounted() && isCurrentlyVisible.current) {
      stream.current = nextStream;
      if (player.current) {
        player.current.srcObject = nextStream;
        setState('ready');
      }
    } else {
      setState('initializing');
      nextStream?.getTracks?.().forEach((track) => {
        track.stop();
      });
    }
    return nextStream;
  });

  const setMode = useEvent((next: CameraMode) => {
    _setMode(next);
    mountCamera();
  });
  const stopCamera = useEvent(async () => {
    setState('initializing');
    stream.current?.getTracks?.().forEach((track) => {
      track.stop();
    });
    stream.current = null;
  });

  useEffect(() => {
    mount && mountCamera();
    return () => {
      stopCamera();
    };
  }, [mountCamera, mount, stopCamera]);

  return {
    actions: {
      mountCamera,
      stopCamera,
      switchCamera,
      takePhoto,
    },
    currentFacingMode,
    errorCode: error,
    mode,
    numberOfCameras,
    photoIdx,
    photos,
    refs: { canvas, container, player },
    setMode,
    setPhotoIdx,
    setPhotos,
    state,
  };
};
export type UseCameraType = ReturnType<typeof useCamera>;

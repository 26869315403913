import { randomIntBetween } from '../lang/randomIntBetween';
import { toSlug } from '../url/toSlug';

export const fileNameWithDate = (fileName: string, _date?: Date, _int?: number) => {
  const date = _date || new Date();
  const rand = _int || randomIntBetween(0, 200);

  return [toSlug(fileName), date.getMonth() + 1, date.getDate(), date.getFullYear(), rand].join(
    '-'
  );
};
